// i18next-extract-mark-ns-start products-ignite-fcl

import { IGNITE_NAV } from '@utils/constants';
import { graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React from 'react';

import Aside from '@components/Aside';
import AssetList from '@components/AssetList';
import Banner from '@components/Banner';
import Breadcrumbs, { Breadcrumb } from '@components/Breadcrumbs';
import PageSection from '@components/PageSection';
import Page from '@components/layout/Page';
import SEO from '@components/layout/SEO';
import { Link, Trans, useTranslation } from 'gatsby-plugin-react-i18next';

const ProductIgnitePage = ({
	pageContext: {
		breadcrumb: { crumbs },
	},
	data
}: {
	pageContext: {
		breadcrumb: { crumbs: Breadcrumb[] };
	};
	data: any;
}) => {
	const { t } = useTranslation(); const image = getImage(data.image.childImageSharp.gatsbyImageData);

	return (
		<Page>
			<SEO
				title={t("seo_title")}
				description={t("seo_description")}
			/>

			<Banner
				title={t("title")}
				image={{
					src: data.banner.childImageSharp.gatsbyImageData,
					alt: '',
				}}
				size="narrow"
			/>

			<Breadcrumbs crumbs={crumbs} />

			<PageSection
				className="section__content section__content--dual"
				layout="none">
				<div className="col xl7 m12 m-spacer page__content page__content--dual">
					<Trans i18nKey="content_1">
						<p>
							IGNITE is capable of modelling fuel cell systems; allowing quick investigation of different control strategies for the balance of plant.
							Using a Fuel Cell Systems library, the user can quickly investigate different control strategies,
							including humidity control, cooling and specification of ancillaries especially the sizing the components to give the required performance.
							The easy to use toolset provides engineers with the capability to tackle the challenge of balancing the fuel cell stack,
							without requiring specific fuel cell expertise and knowledge.
						</p>
					</Trans>

					{image && (
						<p>
							<GatsbyImage image={image} alt="Duty-cycle simulation" />
						</p>
					)}

					<Trans i18nKey="content_2">
						<h2>Key features</h2>

						<p>
							The Modelica library contains all the components relevant to the fuel cell systems modelling for typical fuel cell electric vehicle simulations:
						</p>
						<ul>
							<li>
								Cathode side - compressor, intercooler, humidifier
							</li>
							<li>
								Anode side - hydrogen tank, recirculation blow, pressure control valves
							</li>
							<li>
								Thermal - pumps, heat exchangers and thermostat
							</li>
							<li>
								Controllers and sensors
							</li>
							<li>
								Fluid media (hydrogen, water, glycol, air)
							</li>
						</ul>
					</Trans>
				</div>
				<Aside>
					<Aside.Widget
						title={t("_Applications")}
						className="widget__sibling__pages">
						<AssetList>
							{IGNITE_NAV.map((item, i) => (
								<Link
									key={i}
									to={item.value}
									className="assets__link"
									activeClassName="active">
									{t(item.labelkey) /* i18next-extract-disable-line */}
								</Link>
							))}
						</AssetList>
					</Aside.Widget>
				</Aside>
			</PageSection>
		</Page>
	);
};

export default ProductIgnitePage;

export const pageQuery = graphql`
query ($language: String!) {
	locales: allLocale(
		filter: { ns: { in: ["products-ignite-fcl", "_common"] }, language: { eq: $language } }
	) {
		edges {
			node {
				ns
				data
				language
			}
		}
	}
	banner: file(relativePath: { eq: "content/products/ignite/ignite-hero_1152x654.png" }) {
		...imageBreaker
	}
	image: file(relativePath: { eq: "content/products/ignite/ignite-fuel-cell-library.png" }) {
		...imageStandard
	}
}
`;
